/* eslint-disable react/jsx-no-target-blank */

import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import FavoriteCompare from "components/FavoriteCompare"

import { usePopup } from "context/popup"

const RowItem = ({ item, showInvestment, setPopup }) => (
  <div className="row">
    {showInvestment && (
      <div className="col">
        {item?.acfApartment?.isStandalone
          ? "-"
          : item?.acfApartment?.investment[0]?.title}
      </div>
    )}
    <div className="col">
      {item?.acfApartment?.localNumber
        ? item?.acfApartment?.localNumber
        : item?.title}
    </div>
    <div className="col">
      {item?.acfApartment?.area}m<sup>2</sup>
    </div>
    <div className="col">{item?.acfApartment?.roomsnumber}</div>
    <div className="col">
      {item?.acfApartment?.floor === null ? 0 : item?.acfApartment?.floor}
    </div>
    <div className="col">
      {item?.acfApartment?.status === "available"
        ? "wolne"
        : item?.acfApartment?.status === "reserved"
        ? "zarezerwowane"
        : "sprzedane"}
    </div>
    <div className="col">
      {item?.acfApartment?.cardLink ||
      item?.acfApartment?.pdf?.localFile?.publicURL ? (
        <button
          className="link link--smaller"
          onClick={e => {
            e.preventDefault()
            window.open(
              item?.acfApartment?.cardLink
                ? item?.acfApartment?.cardLink
                : item?.acfApartment?.pdf?.localFile?.publicURL,
              "_blank"
            )
          }}
          target="_blank"
        >
          Pobierz
        </button>
      ) : (
        "-"
      )}
    </div>
    <div className="col-2">
      <button
        className="link link-cta link--smaller"
        onClick={e => {
          e.preventDefault()
          setPopup(
            `Apartament ${
              item?.acfApartment?.localNumber
                ? item?.acfApartment?.localNumber
                : item?.title
            }`
          )
        }}
      >
        Zapytaj o apartament
      </button>
    </div>
    <div className="col-1">
      {item?.acfApartment?.status !== "sold" && (
        <FavoriteCompare id={item?.id} type={"apartment"} />
      )}
    </div>
  </div>
)

const ApartmentRowItem = ({ item, showInvestment, targetBlank }) => {
  const { setPopup } = usePopup()

  return targetBlank ? (
    <a
      href={`${
        item?.acfApartment?.isStandalone
          ? `/apartamenty/${item.slug}/`
          : item?.acfApartment?.investment[0].uri
      }${!item?.acfApartment?.isStandalone ? `${item?.slug}/` : ""}`}
      target="_blank"
      className={`apartment-row-item${
        item?.acfApartment?.status === "sold" ? " apartment-row-item--sold" : ""
      }`}
    >
      <RowItem
        item={item}
        showInvestment={showInvestment}
        setPopup={setPopup}
      />
    </a>
  ) : (
    <Link
      to={`${
        item?.acfApartment?.isStandalone
          ? `/apartamenty/${item.slug}/`
          : item?.acfApartment?.investment[0].uri
      }${!item?.acfApartment?.isStandalone ? `${item?.slug}/` : ""}`}
      className={`apartment-row-item${
        item?.acfApartment?.status === "sold" ? " apartment-row-item--sold" : ""
      }`}
    >
      <RowItem
        item={item}
        showInvestment={showInvestment}
        setPopup={setPopup}
      />
    </Link>
  )
}

export default ApartmentRowItem
